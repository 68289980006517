<template>
	<div class="newscl">
		<!-- header组件     -->
		<vheader :navId="navId"> </vheader>
		<!-- header组件     -->

		<!-- banner组件 -->
		<div class="banner-box">
			<div class="banner-img"></div>
			<div class="banner-nav">
				<div class="banner-nav-content">
					<el-breadcrumb separator-class="el-icon-arrow-right">
						<span><i class="el-icon-location"></i>当前位置：</span>
						<el-breadcrumb-item :to="{ path: 'index' }" title="业务入口">网站首页</el-breadcrumb-item>
						<el-breadcrumb-item :to="{ path: 'mediaCoverage' }" title="媒体报道">媒体报道</el-breadcrumb-item>
						<el-breadcrumb-item :to="{ path: 'newscl1' }" title="新闻详情">新闻详情</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
			</div>
		</div>
		<!-- banner组件 -->
		<div class="newscl_w">
			<div class="newsContentBoxLeft">
				<h1 class="newsContentBoxLeftTitle" id="title">近日北京中关村杂志记者采访恒安康泰（北京）医疗健康科技有限责任公司，了解“一个有温度的互联网医疗平台”</h1>
				<p class="newsContentBoxLeftTime">
					<span>发布时间：2021-06-09 11:47:46</span><span>阅读：347</span><span>来自：恒安康泰新闻中心</span><span>编辑：刘春梅</span>
				</p>
				<hr class="newsContentBoxLeftFenxian">
				<div id="frameContent">
					<p style="line-height: 2em;"><br></p>
					<p style=" font-family: arial, helvetica, sans-serif;">公司两位领导与记者详细介绍了我公司互联网平台极具人性化的服务宗旨以及多元化的服务项目。
					</p>
					<p style=" font-family: arial, helvetica, sans-serif;">
						公司聚焦我国大众医疗重点领域，通过与北京医疗健康各合作方的高效连接，持续打造精准、细致、便捷的赴京就医新生态体系。致力于构建“赴京就医全方位医疗保障平台”，为全国各地的患者，尤其是企事业在职员工提供优质，全面，深入人心的赴京就医诊疗和健康管理服务。
					</p>
					<p style=" font-family: arial, helvetica, sans-serif;">
						公司凭借国内领先的医疗信息管理、健康知识管理、疾病分析管理等行业经验，针对企事业单位职工个人用户，利用“互联网+”手段，构建“恒安康泰医疗一路通”APP，成为提供便捷赴京就医、个人医疗健康管理、企事业人私人医疗健康保障的综合服务线上平台，为企事业人及大众赴京看病就医、日常健康咨询管理等提供便利。
					</p>
					<p style=" font-family: arial, helvetica, sans-serif;">
						恒安康泰医疗健康科技以智慧就医保障为核心，健康咨询和医疗行程服务为辅助，打造企事业人和大众的智慧就医一体化平台，全面赋能大众健康生活。同时，积极延伸至为卫生、医院、药企、养生、健康动态生活产业链赋能。此外，公司可为合作伙伴提供专业赴京医疗就诊咨询服务，特色医院推荐服务，并为企事业人和大众提供便捷、精准、安全、优质的创新型健康检测服务。做到了真正的有温度的互联网医疗平台。
					</p>

					<p style="text-align: center;"><img src="../../assets/img/mediaCoverage/hengannew1.jpg"
							title="中关村杂志记者采访恒安康泰" alt="中关村杂志记者采访恒安康泰" width="500" height="321"></p>
					<p style="text-align: center;"><img src="../../assets/img/mediaCoverage/hengannew2.jpg"
							title="中关村杂志记者采访恒安康泰" alt="中关村杂志记者采访恒安康泰" width="500" height="321"></p>
					<p style="line-height: 2em; text-indent: 2em;">
						恒安康泰团队经过不懈的努力，凭借卓越的科技实力和创新的服务模式，运用“互联网+医疗健康+人文服务”的集人文关怀和科技就医为一体的异地就医新平台，通过了北京中关村海外留学人员创业中心严谨细致的审核，最终成功完成了园区进驻的全部流程，并将获得海淀创业园对初创优质企业的全部孵化资源和政策扶持。<br>
					</p>
					<p style="text-align: center;"><img src="../../assets/img/mediaCoverage/hengannew3.jpg"
							title="中关村杂志记者采访恒安康泰" alt="中关村杂志记者采访恒安康泰" width="500" height="321"></p>
					<p style="text-align: center;"><img src="../../assets/img/mediaCoverage/hengannew4.jpg"
							title="中关村杂志记者采访恒安康泰" alt="中关村杂志记者采访恒安康泰" width="500" height="320"></p>
					<p style="line-height: 2em;"><br></p>
					<p style="line-height: 2em; text-indent: 2em;">
						恒安康泰（北京）医疗健康科技将借此契机，百尺竿头，更进一步，集中技术、市场、资金、服务等进行多重资源整合，努力致力于北京医疗异地普惠业务的发展，让更多的用户可以在异地和赴京期间享受到北京地区三甲医疗服务医疗资源，平衡中西部医疗资源不平衡给患者带来的不便，服务西部民生，做找得到的，有温度的互联网医疗服务。
					</p>
					<p style="line-height: 2em;"><br></p>
					<p style="line-height: 2em;">责任编辑：刘春梅</p>
					<p style="line-height: 2em;"><span style="font-family: 微软雅黑, ">新闻审核：恒安康泰新闻中心</span></p>
				</div>
			</div>
			<div class="listNewsRight">
				<h2 class="stateNewsRighth2">健康热点资讯</h2>
				<hr class="stateNewsRight_h2_hr" />
				<h3 class="stateNewsRighth3">
					<a href="" title="国务新闻">国务新闻</a>
				</h3>
				<hr class="stateNewsRight_h3_hr" />
				<ul class="stateNewsRightUl">
					<li class="stateNewsRightAdverts" v-for="item in rilist1[0]" :key="item.id">
						<i></i><a :href="item.lishref" :title="item.listit">{{ item.listit }}</a>
					</li>
				</ul>
				<h2 class="stateNewsRighth3">恒安图文</h2>
				<hr class="stateNewsRight_h3_hr" />
				<!-- <a href=""><img class="guowuImg" src="../../assets/img/mediaCoverage/guowu1.jpg" alt=""
						title="恒安康泰医疗" /></a> -->
				<h3 class="stateNewsRighth3">
					<a href="" title="名医出诊">名医出诊</a>
				</h3>
				<hr class="stateNewsRight_h3_hr" />
				<ul class="stateNewsRightUl">
					<li class="stateNewsRightAdverts" v-for="item in rilist1[1]" :key="item.id">
						<i></i><a :href="item.lishref" :title="item.listit">{{ item.listit }}</a>
					</li>
				</ul>
				<h3 class="stateNewsRighth3">
					<a href="" title="健康养生">健康养生</a>
				</h3>
				<hr class="stateNewsRight_h3_hr" />
				<ul class="stateNewsRightUl">
					<li class="stateNewsRightAdverts" v-for="item in rilist1[2]" :key="item.id">
						<i></i><a :href="item.lishref" :title="item.listit">{{ item.listit }}</a>
					</li>
				</ul>
				<h2 class="stateNewsRighth3">恒安图文</h2>
				<hr class="stateNewsRight_h3_hr" />
				<a href=""><img class="guowuImg" src="../../assets/img/mediaCoverage/guowu2.jpg" alt=""
						title="恒安康泰医疗" /></a>
				<h3 class="stateNewsRighth3">
					<a href="" title="健康资讯">健康资讯</a>
				</h3>
				<hr class="stateNewsRight_h3_hr" />
				<ul class="stateNewsRightUl">
					<li class="stateNewsRightAdverts" v-for="item in rilist1[3]" :key="item.id">
						<i></i><a :href="item.lishref" :title="item.listit">{{ item.listit }}</a>
					</li>
				</ul>
			</div>
		</div>
		<vfooter></vfooter>
		<GoTop />
	</div>
</template>
<script>
// import banner from "../../components/banner.vue";
import vheader from "../../components/vheader.vue";
import vfooter from "../../components/vfooter.vue";
import GoTop from "@/components/GoTop.vue";
export default {
	name: 'newscl16',
	data() {
		return {
			navName: '新闻详情',
			navUrl: 'newscl16',
			navId: 5,
			rilist1: [
				[{
					listit: "2020年全国居民健康素养水平达23.15%",
					lishref: "#"
				},
				{
					listit: "癌症治疗新希望！我国首个自主研发...",
					lishref: "#"
				},
				{
					listit: "国家卫健委出台人感染动物源性流感...",
					lishref: "#"
				},
				{
					listit: "国家医保局发文，明确推进门诊费用...",
					lishref: "#"
				},
				{
					listit: "国家卫健委：加强儿童中医药服务 推...",
					lishref: "#"
				},
				{
					listit: "这家西南的医院用高品质医疗服务，建...",
					lishref: "#"
				},
				{
					listit: "“十四五”残疾人康复服务蓝图绘就",
					lishref: "#"
				},
				{
					listit: "《2021医生互联网诊疗行为洞察报告》...",
					lishref: "#"
				},
				{
					listit: "六大方面布局！国办发文推动公立医院...",
					lishref: "#"
				},
				{
					listit: "七大城市用户调研指出：智慧医疗未来...",
					lishref: "#"
				},
				],
				[{
					listit: "中国人民解放军总医院院士合集（部分）",
					lishref: "#"
				},
				{
					listit: "U医公益行启动，让泌尿疾病患者在基层...",
					lishref: "#"
				},
				{
					listit: "第十八期“院士+”西部儿科医师培训班座...",
					lishref: "#"
				},
				{
					listit: "福建首例机器人无腹部切口大肠癌手术在...",
					lishref: "#"
				},
				{
					listit: "多位专家赴湖北十堰参加燃气爆炸紧急医...",
					lishref: "#"
				},
				{
					listit: "张楠：阿尔茨海默病患者护理更需专业指导",
					lishref: "#"
				},
				{
					listit: "第一届智慧医疗与医学人文高峰论坛暨医...",
					lishref: "#"
				},
				{
					listit: "提醒身边长痣的朋友，出现以下五种症状...",
					lishref: "#"
				},
				{
					listit: "“疫”路同行的夫妻档",
					lishref: "#"
				},
				{
					listit: "与北京社团合作，中国西部城市乌兰察布...",
					lishref: "#"
				},
				],
				[{
					listit: "水培蔬菜社交网络走红 专家：较土培更...",
					lishref: "#"
				},
				{
					listit: "山药的做法 山药的功效与作用",
					lishref: "#"
				},
				{
					listit: "发青的鸡蛋黄致癌？ 变绿部分无毒，可...",
					lishref: "#"
				},
				{
					listit: "经常失眠，可能是体内缺什么？3种营养...",
					lishref: "#"
				},
				{
					listit: "你知道健康吃饭的黄金标准吗？",
					lishref: "#"
				},
				{
					listit: "5大习惯能『防癌』，很多人都忽视了",
					lishref: "#"
				},
				{
					listit: "每餐4-6小时的间隔期最符合人体消化需...",
					lishref: "#"
				},
				{
					listit: "晨起一杯水别这么喝，风险太大...教你...",
					lishref: "#"
				},
				{
					listit: "心脏有问题，为什么医生还建议让我多运...",
					lishref: "#"
				},
				{
					listit: "缺铁性贫血，补就完事？补血食物别踩坑",
					lishref: "#"
				},
				],
				[{
					listit: "疾控专家邵一鸣对我国目前疫苗功效给出解读",
					lishref: "#"
				},
				{
					listit: "馒头、米饭哪个更容易升血糖？一对比，3餐...",
					lishref: "#",
				},
				{
					listit: "手抖也能通过外科手术治愈啦",
					lishref: "#"
				},
				{
					listit: "身体血糖高 多喝水好还是少喝水好？看完...",
					lishref: "#"
				},
				{
					listit: "练起来 打太极拳能减“游泳圈”",
					lishref: "#"
				},
				{
					listit: "【科学就医我行动】陈勇皮科问答 | 10.皮...",
					lishref: "#"
				},
				{
					listit: "维生素C有什么功效和副作用？",
					lishref: "#"
				},
				{
					listit: "“提高免疫力”，被过度夸大化，免疫平衡比“...",
					lishref: "#",
				},
				{
					listit: "胃癌如何选择最合适疗方案？CA最新刊文指...",
					lishref: "#"
				},
				{
					listit: "九价HPV疫苗为何“一针难求”",
					lishref: "#"
				},
				],
			],
		};
	},
	components: {
		// banner,
		vheader,
		vfooter,
		GoTop,
	},
};
</script>
<style lang="less">
@media screen and (min-width:750px) {
	.banner-box {
		.banner-img {
			height: 280Px;
			background: url(../../assets/img/banner/banner01.jpg) no-repeat center top / 100%;
		}

		.banner-nav {
			background-color: #f6f8fb;

			.banner-nav-content {
				width: 1200Px;
				margin: auto;

				.el-breadcrumb {
					line-height: 70Px;
				}

				.el-breadcrumb__inner.is-link {
					font-weight: unset;
					color: #333;
					cursor: pointer;

					&:hover {
						color: #093C6C;
						text-decoration: underline;
					}
				}

				span {
					display: flex;
					align-items: center;

					.el-icon-location {
						color: #093C6C;
						font-size: 20Px;
						margin-right: 12Px;
					}

					float: left;
					color:#5a5a5a;
				}
			}
		}
	}
}

@media screen and (max-width:750px) {
	.banner-box {
		.banner-img {
			height: 110px;
			background: url(../../assets/img/banner/banner01.jpg) no-repeat center top / 200%;
		}

		.banner-nav {
			background-color: #f6f8fb;

			.banner-nav-content {
				width: 95%;
				margin: auto;

				.el-breadcrumb {
					line-height: 35px;
				}

				.el-breadcrumb__inner.is-link {
					font-weight: unset;
					color: #333;
					cursor: pointer;

					&:hover {
						color: #093C6C;
						text-decoration: underline;
					}
				}

				span {
					display: flex;
					align-items: center;

					.el-icon-location {
						color: #093C6C;
						font-size: 19px;
						margin-right: 2px;
					}

					float: left;
					color:#5a5a5a;
				}
			}
		}
	}
}
</style>
